


















































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  feature,
  Feature,
  FeatureCollection,
  featureCollection,
  MultiPolygon,
  Polygon,
  polygon,
  multiPolygon
} from '@turf/helpers';
import simplify from '@turf/simplify';
import area from '@turf/area';
import truncate from '@turf/truncate';
import union from '@turf/union';
import cleanCoords from '@turf/clean-coords';
import moment from 'moment';
import { Country } from '@/interfaces/country';
import { Entity } from '@/interfaces/entity';
import { Domain } from '@/interfaces/domain';
import { Mapping } from '@/interfaces/mapping';
import { Farm } from '@/interfaces/farm';
import { FarmMapping } from '@/interfaces/farmMapping';
import { Parcel } from '@/interfaces/parcel';
import { ParcelMapping } from '@/interfaces/parcelMapping';
import * as FileSaver from 'file-saver';
import MapEntityModal from '@/components/MapEntityModal.vue';
import { Item } from '@/interfaces/item';
import CreateEntityModal from '@/components/CreateEntityModal.vue';
import API from '@/services/api';
import { CropType } from '@/interfaces/cropType';
import { message } from 'ant-design-vue';
import ManageOverlappingModal from '@/components/ManageOverlappingModal.vue';
import { OverlapMapping } from '@/interfaces/overlapMapping';
import { isIdFake, getFakeId } from '@/services/utils';
import { UploadRequest } from '@/interfaces/uploadRequest';
import { Shape } from '@/interfaces/shape';
import ManageFarmOverlappingModal from '@/components/ManageFarmOverlappingModal.vue';
import { FarmOverlapMapping } from '@/interfaces/farmOverlapMapping';
import { SourceParcelOverlapMapping } from '@/interfaces/sourceParcelOverlapMapping';
import ManageSourceParcelOverlappingModal from '@/components/ManageSourceParcelOverlappingModal.vue';
import kinks from '@turf/kinks';
import buffer from '@turf/buffer';
import ShapeEditingModal from '@/components/ShapeEditingModal2.vue';
import * as shp from 'shpjs';
import JSZip from 'jszip';
import { AuthorizeResponse } from '@/interfaces/auth';
import { ShapeEditingResult } from '@/interfaces/shapeEditingResult';
import csvtojson from 'csvtojson';
import polygonClipping from 'polygon-clipping';
const farmOverlapWorker = new Worker('../services/farm-overlap.worker', { type: 'module' });
const sourceParcelOverlapWorker = new Worker('../services/source-parcel-overlap.worker', { type: 'module' });
const clusteringWorker = new Worker('../services/clustering.worker', { type: 'module' });

@Component({
  components: {
    MapEntityModal,
    CreateEntityModal,
    ManageOverlappingModal,
    ManageFarmOverlappingModal,
    ManageSourceParcelOverlappingModal,
    ShapeEditingModal
  }
})
export default class Upload extends Vue {
  shape: FeatureCollection = null;
  private shapeName: string = null;
  mahindraValidationOutput: string = null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  properties: object = {};
  shapeFields: string[] = [];
  cropTypes: CropType[] = [];
  countries: Country[] = [];
  entities: Entity[] = [];
  domains: Domain[] = [];
  farms: Farm[] = [];
  parcels: Parcel[] = [];
  selectedCountryId: string = null;
  selectedEntityId: string = null;
  selectedUnit: string = null;
  private selectedDomainField: string = null;
  private selectedFarmField: string = null;
  private selectedFarmCodeField: string = null;
  private selectedParcelField: string = null;
  selectedCropTypeId: string = null;
  private selectedPlantingDateField: string = null;
  private selectedDeletionDateField: string = null;
  private selectedCreationDateField: string = null;
  private selectedHarvestingDateField: string = null;
  private selectedVarietyField: string = null;
  private selectedStatusField: string = null;
  private selectedRotationField: string = null;
  private selectedFarmerNameField: string = null;
  private selectedFarmerCodeField: string = null;
  private selectedCropSeasonField: string = null;
  private selectedFarmerPhoneNumberField: string = null;
  private selectedDivisionField: string = null;
  propertyDateMask = 'YYYY-MM-DD';
  propertyDeletionDateMask = 'YYYY-MM-DD';
  propertyHarvestingDateMask = 'YYYY-MM-DD';
  propertyCreationDateMask = 'YYYY-MM-DD';
  manualPlantingDateField = '';
  manualDeletionDateField = '';
  manualCreationDateField = '';
  manualHarvestingDateField = '';
  manualDomainField = '';
  manualFarmField = '';
  parsedPropertyDate = '';
  parsedPropertyDeletionDate = '';
  parsedPropertyCreationDate = '';
  parsedPropertyHarvestingDate = '';
  shapeDomainNames: Mapping[] = [];
  shapeFarmNames: FarmMapping[] = [];
  shapeParcelNames: ParcelMapping[] = [];
  private readonly plantingDateMask = 'YYYY-MM-DD';
  private readonly maxOldPlantingDateYears = 20;
  createDomainItemName: string = null;
  createFarmItemName: string = null;
  createFarmItemCode: string = null;
  createParcelItemName: string = null;
  mapDomainItemName: string = null;
  mapFarmItemName: string = null;
  mapParcelItemName: string = null;
  kinksCounter = 0;
  shapeEditingResult: ShapeEditingResult = null;
  private uploadRequest: UploadRequest = null;

  private isSuccessfullyUploaded = false;
  private isManageOverlappingVisible = false;
  public farmOverlapMapping: FarmOverlapMapping[] = [];
  public sourceParcelOverlapMapping: SourceParcelOverlapMapping[] = [];
  private isManageFarmOverlappingVisible = false;
  private isManageSourceParcelOverlappingVisible = false;
  private isShapeEditingVisible = false;
  private modifiedDates: { props: any; plantingDate: string }[] = [];
  private shapeParcelNamesArea: { [key: string]: string[] } = {};
  private shapeParcelUniqueNameCounter = 0;
  private propertiesPlantingDateName = 'planting_date';
  private propertiesCreationDateName = 'creation_date';
  private propertiesDeletionDateName = 'deletion_date';
  private propertiesHarvestingDateName = 'harvesting_date';

  private testPolygon = polygon([
    [
      [13.0050659, 82.0135588],
      [13.0586242, 82.0122231],
      [13.0421447, 82.0198529],
      [13.0050659, 82.0135588]
    ]
  ]);

  genericColumns = [
    {
      title: 'Property',
      dataIndex: 'featureName'
    },
    {
      title: 'DB',
      dataIndex: 'dbName'
    },
    {
      title: 'Action',
      scopedSlots: { customRender: 'action' }
    }
  ];
  farmColumns = [
    {
      title: 'Name',
      dataIndex: 'featureName'
    },
    {
      title: 'Code',
      dataIndex: 'featureCode'
    },
    {
      title: 'DB Name',
      dataIndex: 'dbName'
    },
    {
      title: 'DB Code',
      dataIndex: 'dbCode'
    },
    {
      title: 'Action',
      scopedSlots: { customRender: 'action' }
    }
  ];
  parcelColumns = [
    {
      title: 'Property',
      dataIndex: 'featureName'
    },
    {
      title: 'DB',
      scopedSlots: { customRender: 'dbName' }
    },
    {
      title: 'Planting date',
      dataIndex: 'plantingDate'
    },
    {
      title: 'Variety',
      dataIndex: 'variety'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Farmer Name',
      dataIndex: 'farmerName'
    },
    {
      title: 'Farmer Code',
      dataIndex: 'farmerCode'
    },
    {
      title: 'Crop Season',
      dataIndex: 'cropSeason'
    },
    {
      title: 'Farmer Phone Number',
      dataIndex: 'farmerPhoneNumber'
    },
    {
      title: 'Division',
      dataIndex: 'division'
    },
    {
      title: 'Area',
      dataIndex: 'area',
      scopedSlots: { customRender: 'area' }
    },
    {
      title: 'Action',
      scopedSlots: { customRender: 'action' }
    }
  ];
  MAX_SHAPE_AREA = 15000000;

  isClusteringEnabled = false;
  private refreshSessionInterval = 600000; // millisecond, 10 min

  mounted(): void {
    this.$store.dispatch('showGlobalLoader');
    API.authorize('shape-uploader').then((response: AuthorizeResponse) => {
      if (!response.success) {
        if (response.message) {
          message.error(response.message, 0);
          return;
        } else {
          window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
        }
      }
      this.$store.dispatch('setUserInfo', response.UserInfo);
      Promise.all([API.getCountries(), API.getCropTypes()])
        .then((result) => {
          this.countries = result[0];
          this.cropTypes = result[1];
        })
        .finally(() => {
          this.$store.dispatch('hideGlobalLoader');
        });
    });

    setInterval(() => {
      API.refreshSession();
    }, this.refreshSessionInterval);
  }

  getSortedShapeParcelNames(): ParcelMapping[] {
    const items = [...this.shapeParcelNames];
    items.sort((a: ParcelMapping, b: ParcelMapping) => (a.dbId && !b.dbId ? 1 : -1));
    return items;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  filterOption(input: string, option: any): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  get kinksErrorMessage(): string {
    return `Provided geoJSON file contains ${this.kinksCounter} self-intersection polygons! They are skipped. See console.log for details.`;
  }

  async handleChange(event: Event): Promise<void> {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length) {
      await this.$store.dispatch('showGlobalLoader', 'Loading and validating file...');
      this.shapeName = files[0].name;
      let jsonData: FeatureCollection = featureCollection([]);
      try {
        if (files.length > 1) {
          for (let i = 0; i < files.length; i++) {
            if (files[i].name.toLowerCase().endsWith('.shp')) {
              this.shapeName = files[i].name;
              break;
            }
          }
          jsonData = (await this.shpFilesToGeoJson(files)) as FeatureCollection;
        } else {
          if (this.shapeName.toLowerCase().endsWith('.zip')) {
            jsonData = await this.shpZipToGeoJson(await this.readFile(files[0], false));
          } else if (this.shapeName.toLowerCase().endsWith('.csv')) {
            jsonData = await this.csvToJson((await this.readFile(files[0], true)) as string);
          } else {
            jsonData = JSON.parse((await this.readFile(files[0], true)) as string) as FeatureCollection;
            jsonData = (await this.transform(jsonData)) as FeatureCollection;
          }
        }
      } catch (e) {
        message.error('Something wrong with provided files, check console for details.');
        // eslint-disable-next-line no-console
        console.error(e);
      }
      await this.onJsonDataLoaded(jsonData);
      await this.$store.dispatch('hideGlobalLoader');
    }
  }

  private async csvToJson(text: string): Promise<FeatureCollection> {
    //Used noheader, eventhough there is header,
    //as same column heading is used in multiple columns.
    const rows = await csvtojson({ noheader: true }).fromString(text);
    let jsonData = featureCollection([]);
    let firstRow = true;
    rows.forEach((row) => {
      if (firstRow) {
        firstRow = false;
        return true;
      }
      let points = Object.keys(row).length - 10;
      const coords = [];
      for (let i = 0; i < points; i += 2) {
        if (row['field' + (5 + i)].trim().length == 0) break;
        coords.push([parseFloat(row['field' + (5 + i + 1)]), parseFloat(row['field' + (5 + i)])]);
      }
      coords.push([parseFloat(row['field6']), parseFloat(row['field5'])]);

      if (coords.filter((x) => x.filter((y) => y === 0 || isNaN(y)).length > 0).length > 0) {
        return true;
      }
      jsonData.features.push({
        type: 'Feature',
        properties: {
          Parcel: row['field1'],
          Status: row['field2'],
          PlantingDate: row['field3'],
          Variety: row['field' + (5 + points)],
          Circle: row['field' + (8 + points)],
          Village: row['field' + (10 + points)],
          VillageCode: row['field' + (9 + points)]
        },
        geometry: { type: 'Polygon', coordinates: [coords] }
      });
    });
    return jsonData;
  }

  private async transform(json: any): Promise<any> {
    if (
      json.crs &&
      json.crs.properties &&
      json.crs.properties.name &&
      json.crs.properties.name.indexOf(':4326') === -1 &&
      json.crs.properties.name.indexOf('CRS84') === -1
    ) {
      return await API.transform(json);
    }
    return json;
  }

  private async onJsonDataLoaded(jsonData: FeatureCollection): Promise<void> {
    this.shape = this.fixShape(jsonData);

    if (this.isClusteringEnabled) {
      const clusteredData = await this.clustering(this.shape);
      this.shape = this.scaleShape(clusteredData);
    }

    if (this.shape && this.shape.features && this.shape.features.length) {
      const properties = { ...this.shape.features[0].properties };
      this.properties = properties;
      this.shapeFields = Object.keys(properties);
    }
  }

  private validatePrjFile(prjStr: string): boolean {
    if (prjStr.indexOf('D_South_American_1969') !== -1) {
      message.error('South American Datum is not supported, please convert file to WGS84');
      return false;
    }
    return true;
  }

  private async shpFilesToGeoJson(files: FileList) {
    if (files.length !== 3) {
      message.error('All 3 shape file components must be selected.');
      return;
    }
    let shpBuffer, prjStr, dbf: any;
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name.toLowerCase();
      if (fileName.endsWith('.shp')) {
        shpBuffer = await this.readFile(files[i], false);
      } else if (fileName.endsWith('.prj')) {
        prjStr = await this.readFile(files[i], true);
      } else if (fileName.endsWith('.dbf')) {
        dbf = await this.readFile(files[i], false);
      }
    }
    if (!shpBuffer || !dbf || !prjStr) {
      message.error('Invalid shape file data');
      return null;
    }
    if (!this.validatePrjFile(prjStr as string)) {
      return null;
    }
    const shapes = shp.combine([shp.parseShp(shpBuffer, prjStr), shp.parseDbf(dbf)]);
    if (!shapes) {
      message.error('Invalid shape file data');
      return null;
    }
    return shapes;
  }

  private async readFile(file: File, isText: boolean) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      if (isText) fileReader.readAsText(file);
      else fileReader.readAsArrayBuffer(file);
    });
  }
  private async shpZipToGeoJson(input: any) {
    try {
      const zip = new JSZip();
      const data = await zip.loadAsync(input);
      let validFile = true;
      let prjFile = null;
      ['shp', 'prj', 'dbf'].map((ext) => {
        const file = Object.keys(data.files).find((key) => key.slice(-3).toLowerCase() === ext);
        if (!file) {
          message.error(ext + ' file must exist');
          validFile = false;
          return null;
        }
        if (ext === 'prj') {
          prjFile = file;
        }
      });
      if (prjFile) {
        const prjStr = await data.files[prjFile].async('string');
        if (!this.validatePrjFile(prjStr)) {
          return null;
        }
      }
      if (validFile) {
        return await shp(input);
      }
    } catch (err) {
      message.error('Invalid shape file. Error: ' + err);
    }
    return null;
  }

  private isValidPolygon(polygon: Feature<Polygon>): boolean {
    const rings = polygon.geometry.coordinates;
    if (rings.length === 0) {
      return false;
    }
    for (let i = 0; i < rings.length; i++) {
      if (rings[i].length < 4) {
        return false;
      }
    }
    return true;
  }

  private simplifyPolygon(polygon: Feature<Polygon>): Feature<Polygon> {
    try {
      const tv = truncate(polygon, { precision: 8 });
      const cv = cleanCoords(tv);
      if (this.isValidPolygon(cv)) {
        try {
          if (kinks(cv).features.length === 0) {
            // extra check if polygon is valid
            union(cv, this.testPolygon);
            return simplify(cv, { tolerance: 0.00000001, highQuality: true });
          }
          this.kinksCounter++;
          throw new Error('kinks');
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err, JSON.stringify(cv));
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err, JSON.stringify(polygon));
    }
    return null;
  }

  private scaleShape(shape: FeatureCollection): FeatureCollection {
    let features: Array<Feature<Polygon>> = [];
    shape.features.forEach((feature: Feature) => {
      const buffered = buffer(feature, -5, { units: 'meters' }) as Feature<Polygon>;
      features.push(buffered);
    });
    return featureCollection(features);
  }

  private fixShape(shape: FeatureCollection): FeatureCollection {
    this.kinksCounter = 0;
    if (shape && shape.features && shape.features.length) {
      const features: Array<Feature<Polygon>> = [];
      shape.features.forEach((feature: Feature) => {
        if (feature.geometry.type === 'Polygon') {
          const simplified = this.simplifyPolygon(feature as Feature<Polygon>);
          if (simplified) {
            features.push(simplified);
          }
        }
        if (feature.geometry.type === 'MultiPolygon') {
          const multiPolygon = feature as Feature<MultiPolygon>;
          multiPolygon.geometry.coordinates.forEach((coords) => {
            try {
              const simplified = this.simplifyPolygon(polygon(coords, { ...feature.properties }));
              if (simplified) {
                features.push(simplified);
              }
            } catch (err) {
              // eslint-disable-next-line no-console
              console.error(err, JSON.stringify(coords));
            }
          });
        }
      });
      if (features.length) {
        return featureCollection(features);
      }
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parsePlantingDate(properties: any): string {
    if (this.modifiedDates.length > 0) {
      const match = this.modifiedDates.filter((x) => JSON.stringify(x.props) === JSON.stringify(properties));
      if (match.length > 0) {
        return match[0].plantingDate;
      }
    }
    let date = moment(
      this.manualPlantingDateField || properties[this.selectedPlantingDateField],
      this.propertyDateMask
    );
    if (!date.isValid()) {
      date = moment().startOf('year');
    }
    return date.format(this.plantingDateMask);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseCreationDate(properties: any): string {
    let date = moment(
      this.manualCreationDateField || properties[this.selectedCreationDateField],
      this.propertyCreationDateMask
    );
    if (!date.isValid()) {
      date = moment().startOf('year');
    }
    return date.format(this.plantingDateMask);
  }

  private parseDeletionDate(properties: any): string {
    let date = moment(
      this.manualDeletionDateField || properties[this.selectedDeletionDateField],
      this.propertyDeletionDateMask
    );
    if (!date.isValid()) {
      date = moment(new Date(2100, 0, 1));
    }
    return date.format(this.plantingDateMask);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseHarvestingDate(properties: any): string {
    let date = moment(
      this.manualHarvestingDateField || properties[this.selectedHarvestingDateField],
      this.propertyHarvestingDateMask
    );
    if (!date.isValid()) {
      return null;
    }
    return date.format(this.plantingDateMask);
  }

  handleCropTypeChange(id: string): void {
    this.selectedCropTypeId = id;
  }
  handleVarietyChange(name: string): void {
    this.selectedVarietyField = name;
  }
  handleStatusChange(name: string): void {
    this.selectedStatusField = name;
  }
  handleRotationChange(name: string): void {
    this.selectedRotationField = name;
  }
  handleFarmerNameChange(name: string): void {
    this.selectedFarmerNameField = name;
  }
  handleFarmerCodeChange(name: string): void {
    this.selectedFarmerCodeField = name;
  }
  handleCropSeasonChange(name: string): void {
    this.selectedCropSeasonField = name;
  }
  handleFarmerPhoneNumberChange(name: string): void {
    this.selectedFarmerPhoneNumberField = name;
  }
  handleDivisionChange(name: string): void {
    this.selectedDivisionField = name;
  }
  handlePlantingDateChange(name: string): void {
    this.selectedPlantingDateField = name;
    this.onParsePlantingDate();
  }

  handleDeletionDateChange(name: string): void {
    this.selectedDeletionDateField = name;
    this.onParseDeletionDate();
  }

  handleCreationDateChange(name: string): void {
    this.selectedCreationDateField = name;
    this.onParseCreationDate();
  }

  handleHarvestingDateChange(name: string): void {
    this.selectedHarvestingDateField = name;
    this.onParseHarvestingDate();
  }

  handleDomainFieldChange(name: string): void {
    this.selectedDomainField = name;
    this.onSelectDomain();
  }

  handleFarmFieldChange(name: string): void {
    this.selectedFarmField = name;
    this.onSelectFarm();
  }

  handleFarmCodeFieldChange(code: string): void {
    this.selectedFarmCodeField = code;
    this.onSelectFarm();
  }

  handleParcelFieldChange(name: string): void {
    this.selectedParcelField = name;
    this.onSelectParcel();
  }

  handleCountryChange(id: string): void {
    this.selectedCountryId = id;
    this.$store.dispatch('showGlobalLoader', 'Loading organizations...');
    API.getOrganizations(id)
      .then((entities: Entity[]) => {
        this.entities = entities;
        this.selectedEntityId = null;
      })
      .finally(() => {
        this.$store.dispatch('hideGlobalLoader');
      });
  }

  handleEntityChange(id: string): void {
    this.selectedEntityId = id;
    this.$store.dispatch('showGlobalLoader', 'Loading units...');
    API.getUnits(id)
      .then((domains: Domain[]) => {
        this.domains = domains;
        if (this.shapeDomainNames.length) {
          this.onSelectDomain();
        }
      })
      .finally(() => {
        this.$store.dispatch('hideGlobalLoader');
      });
  }

  getParcelDbName(item: ParcelMapping): string {
    if (item.overlapMapping.length > 0) {
      return item.overlapMapping.map((o: OverlapMapping) => `${o.dbName} (${o.percent}%)`).join(', ');
    }
    return item.dbName;
  }

  onParsePlantingDate(): void {
    this.parsedPropertyDate = this.parsePlantingDate(this.properties);
  }

  onParseCreationDate(): void {
    this.parsedPropertyCreationDate = this.parseCreationDate(this.properties);
  }

  onParseDeletionDate(): void {
    this.parsedPropertyDeletionDate = this.parseDeletionDate(this.properties);
  }
  onParseHarvestingDate(): void {
    this.parsedPropertyHarvestingDate = this.parseHarvestingDate(this.properties);
  }

  onSelectDomain(): void {
    const shapeDomainNames: Mapping[] = [];
    const values = this.manualDomainField
      ? [this.manualDomainField]
      : this.shape
      ? this.shape.features.map((feature: Feature) => feature.properties[this.selectedDomainField])
      : null;
    if (values && values.length) {
      new Set(values).forEach((value: string) => {
        const domain = this.domains
          ? this.domains.find((domain: Domain) => domain.Name.toLowerCase() === `${value}`.toLowerCase())
          : null;
        shapeDomainNames.push({
          featureName: value,
          dbId: domain ? domain.id : null,
          dbName: domain ? domain.Name : null
        });
      });
    }
    this.shapeDomainNames = shapeDomainNames;
    this.reloadFarms();
  }

  private reloadFarms(): void {
    this.$store.dispatch('showGlobalLoader', 'Loading farms...');
    const farmTasks: Array<Promise<Farm[]>> = [];
    this.shapeDomainNames.forEach((shapeDomainName: Mapping) => {
      if (shapeDomainName.dbId) {
        this.selectedUnit = shapeDomainName.dbId;
        farmTasks.push(isIdFake(shapeDomainName.dbId) ? Promise.resolve([]) : API.getFarms(shapeDomainName.dbId));
      }
    });
    Promise.all(farmTasks)
      .then((result: Array<Farm[]>) => {
        this.farms = result.filter((farm: Farm[]) => !!farm).flat();
        if (this.shapeFarmNames.length) {
          this.onSelectFarm();
        }
      })
      .finally(() => {
        this.$store.dispatch('hideGlobalLoader');
      });
  }

  onSelectFarm(): void {
    const shapeFarmNames: FarmMapping[] = [];
    const values: string[] = [];
    if (this.shape) {
      this.shape.features.forEach((feature: Feature) => {
        const farmName = this.manualFarmField || feature.properties[this.selectedFarmField] || '';
        const farmCode = feature.properties[this.selectedFarmCodeField] || '';
        if (!values.includes(farmName + '|' + farmCode)) {
          const domainName = this.manualDomainField || feature.properties[this.selectedDomainField] || '';
          const farm = this.farms
            ? this.farms.find(
                (farm: Farm) =>
                  (farm.Name || '').toLowerCase() === `${farmName}`.toLowerCase() && farm.Code === `${farmCode}`
              )
            : null;
          shapeFarmNames.push({
            featureName: farmName,
            featureCode: farmCode,
            dbId: farm ? farm.id : null,
            dbName: farm ? farm.Name : null,
            dbCode: farm ? farm.Code : null,
            featureDomainName: domainName,
            validated: false
          });
          values.push(farmName + '|' + farmCode);
        }
      });
    }
    this.shapeFarmNames = shapeFarmNames;
    this.reloadParcels();
  }

  private reloadParcels(): void {
    this.$store.dispatch('showGlobalLoader', 'Loading parcels...');
    const parcelTasks: Array<Promise<Parcel[]>> = [];
    this.shapeFarmNames.forEach((shapeFarmName: FarmMapping) => {
      if (shapeFarmName.dbId) {
        parcelTasks.push(isIdFake(shapeFarmName.dbId) ? Promise.resolve([]) : API.getParcels(shapeFarmName.dbId));
      }
    });
    Promise.all(parcelTasks)
      .then((result: Array<Parcel[]>) => {
        this.parcels = result.filter((parcel: Parcel[]) => !!parcel).flat();
        if (this.shapeParcelNames.length) {
          this.onSelectParcel();
        }
      })
      .finally(() => {
        this.$store.dispatch('hideGlobalLoader');
      });
  }

  private onSelectParcel(): void {
    if (!this.selectedParcelField) {
      return;
    }
    const validatedFarmNames = this.shapeFarmNames
      .filter((farmMapping: FarmMapping) => farmMapping.validated)
      .map((farmMapping: FarmMapping) => farmMapping.featureName);
    const shapeParcelNames: ParcelMapping[] = [];
    this.shapeParcelNamesArea = {};
    if (this.shape) {
      this.shape.features.forEach((feature: Feature) => {
        const parcelName = feature.properties[this.selectedParcelField] || '';
        this.shapeParcelNamesArea[parcelName] = this.shapeParcelNamesArea[parcelName] || [];
        this.shapeParcelNamesArea[parcelName].push(area(feature).toFixed());
      });

      const momentMaxOldPlantingDate = moment().subtract(this.maxOldPlantingDateYears, 'years');
      this.shape.features.forEach((feature: Feature) => {
        const farmName = this.manualFarmField || feature.properties[this.selectedFarmField] || '';
        if (validatedFarmNames.includes(farmName)) {
          const farmCode = feature.properties[this.selectedFarmCodeField] || '';
          const parcelName = this.getParcelName(feature);
          let parcel = null;
          if (this.shapeEditingResult && this.shapeEditingResult.mapping.length) {
            const mapping = this.shapeEditingResult.mapping.find(({ feature }) => {
              return parcelName === this.getParcelName(feature);
            });
            if (mapping) {
              parcel = mapping.parcel;
            }
          }

          const plantingDate = this.parsePlantingDate(feature.properties);
          const creationDate = this.parseCreationDate(feature.properties);
          const isOldPlantingDate = moment(plantingDate, this.plantingDateMask).isBefore(momentMaxOldPlantingDate);

          shapeParcelNames.push({
            featureName: parcelName,
            dbId: parcel ? parcel.id : null,
            dbName: parcel ? parcel.Name : null,
            featureFarmName: farmName,
            featureFarmCode: farmCode,
            farmerName: feature.properties.FarmerName,
            farmerCode: feature.properties.FarmerCode,
            cropSeason: feature.properties.CropSeason,
            farmerPhoneNumber: feature.properties.FarmerPhoneNumber,
            division: feature.properties.Division,
            featureShape: feature.geometry as Polygon,
            plantingDate,
            creationDate,
            harvestDates: parcel && parcel.HarvestDates && parcel.HarvestDates.length ? parcel.HarvestDates : [],
            variety: '' + (feature.properties[this.selectedVarietyField] ?? ''),
            status: feature.properties[this.selectedStatusField] ?? '',
            rotation: feature.properties[this.selectedRotationField]
              ? JSON.stringify(feature.properties[this.selectedRotationField])
              : '',
            area: area(feature).toFixed(),
            overlapMapping: [],
            isOldPlantingDate,
            skipUpload: isOldPlantingDate,
            skipUploadManually: false
          });
        }
      });
    }
    this.shapeParcelNames = shapeParcelNames;
  }

  getMatchingStatistics(shapes: Mapping[]): string {
    return `${shapes.filter(({ dbId }) => !!dbId).length}/${shapes.length}`;
  }

  private getParcelName(feature: Feature): string {
    let parcelName = feature.properties[this.selectedParcelField];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allAreas = (this.shapeParcelNamesArea as any)[parcelName];
    if (allAreas && allAreas.length > 1) {
      const featureArea = area(feature).toFixed();
      const areasFeature = allAreas.filter((a: string) => a === featureArea);
      if (areasFeature.length === 1) {
        parcelName = `${parcelName}-${featureArea}`;
      } else {
        parcelName = `${parcelName}-${featureArea}-${this.shapeParcelUniqueNameCounter}`;
        this.shapeParcelUniqueNameCounter++;
      }
    }
    return parcelName;
  }

  private updateShapeProperties(): void {
    if (this.shape) {
      this.shape.features.forEach((feature: Feature) => {
        feature.properties.entity = this.selectedEntityId;
        const domainField = this.manualDomainField || feature.properties[this.selectedDomainField] || '';
        const shapeDomain = this.shapeDomainNames.find(({ featureName }) => featureName === domainField);
        if (shapeDomain) {
          feature.properties.domain = shapeDomain.dbId;
        }
        const farmField = this.manualFarmField || feature.properties[this.selectedFarmField] || '';
        const farmCode = feature.properties[this.selectedFarmCodeField] || '';
        const shapeFarm = this.shapeFarmNames.find(
          ({ featureName, featureCode }) => featureName === farmField && featureCode === farmCode
        );
        if (shapeFarm) {
          feature.properties.farm = shapeFarm.dbId;
        }
        const parcelField = this.getParcelName(feature);
        const shapeParcel = this.shapeParcelNames.find(({ featureName }) => featureName === parcelField);
        if (shapeParcel && !shapeParcel.skipUpload && !shapeParcel.skipUploadManually) {
          feature.properties.parcel = shapeParcel.dbId;
          feature.properties.area = shapeParcel.area;
        }
        feature.properties['crop_type'] = this.selectedCropTypeId;
        feature.properties[this.propertiesPlantingDateName] = this.parsePlantingDate(feature.properties);
        feature.properties[this.propertiesDeletionDateName] = this.parseCreationDate(feature.properties);
        feature.properties[this.propertiesDeletionDateName] = this.parseDeletionDate(feature.properties);
        feature.properties[this.propertiesHarvestingDateName] = this.parseHarvestingDate(feature.properties);
      });
    }
  }

  private isAllResolved(shapes: Mapping[]): boolean {
    return shapes.filter(({ dbId }) => !!dbId).length === shapes.length;
  }

  get isAllDomainsResolved(): boolean {
    return this.isAllResolved(this.shapeDomainNames);
  }

  get isAllFarmsResolved(): boolean {
    return this.isAllResolved(this.shapeFarmNames);
  }

  get isAllParcelsResolved(): boolean {
    return this.shapeParcelsToUpload.filter(({ dbId }) => !!dbId).length === this.shapeParcelsToUpload.length;
  }

  get someParcelsHaveOldPlantingDate(): boolean {
    return this.shapeParcelNames.some(({ isOldPlantingDate }) => isOldPlantingDate);
  }

  get shapeParcelsToUpload(): ParcelMapping[] {
    return this.shapeParcelNames.filter(({ skipUpload }) => !skipUpload);
  }

  get hasParcelsToUpload(): boolean {
    return this.shapeParcelsToUpload.length > 0;
  }

  get isParcelsContainOverlapping(): boolean {
    return this.shapeParcelNames.some((shapeParcel: ParcelMapping) => shapeParcel.overlapMapping.length > 0);
  }

  get isAreaInvalid(): boolean {
    return this.shapeParcelNames.some((shapeParcel: ParcelMapping) => {
      return parseFloat(shapeParcel.area) > this.MAX_SHAPE_AREA;
    });
  }

  get isSaveDisabled(): boolean {
    return (
      this.isAreaInvalid || !this.isAllParcelsResolved || !this.selectedParcelField || this.isParcelsContainOverlapping
    );
  }

  save(): void {
    this.updateShapeProperties();
    if (this.shape) {
      FileSaver.saveAs(new Blob([JSON.stringify(this.shape)]), this.getShapeName());
    }
  }

  get entitiesToUploadToServer(): any {
    return {
      edited: this.shapeEditingResult
        ? Object.values(this.shapeEditingResult.editedDbParcels).filter(({ feature }) => !feature.properties.invalid)
            .length
        : 0,
      deleted: this.shapeEditingResult ? Object.values(this.shapeEditingResult.deletedDbParcels).length : 0
    };
  }

  saveResultAndUpload(result: ShapeEditingResult): void {
    this.shapeEditingResult = result;
    this.uploadToServerDbChanges();
  }

  uploadToServerDbChanges(): void {
    const uploadRequest = {
      CountryID: this.selectedCountryId,
      CropID: this.selectedCropTypeId,
      OrganizationID: this.selectedEntityId,
      Units: []
    } as UploadRequest;

    this.addToUploadRequestDbChanges(uploadRequest);

    this.$store.dispatch('showGlobalLoader', 'Uploading...');
    this.doUpload(uploadRequest)
      .then(() => {
        message.success('Loading finished', 5);
        this.shapeEditingResult.editedDbParcels = {};
        this.shapeEditingResult.deletedDbParcels = {};
      })
      .catch(() => {
        message.error('Something went wrong', 5);
      })
      .finally(() => {
        this.$store.dispatch('hideGlobalLoader');
      });
  }

  saveRemaining(): void {
    this.updateShapeProperties();
    if (this.shape) {
      const approvedFarmIds = this.shapeFarmNames.filter(({ validated }) => !!validated).map(({ dbId }) => dbId);
      const features = this.shape.features.filter((feature) => !approvedFarmIds.includes(feature.properties.farm));
      FileSaver.saveAs(new Blob([JSON.stringify(featureCollection(features))]), this.getShapeName(true));
    }
  }

  private addToUploadRequest(
    parcel: Parcel,
    uploadRequest: UploadRequest,
    shapeFeature: Feature,
    parcelFarm?: Farm
  ): void {
    const farm = parcelFarm || this.farms.find((farm: Farm) => farm.id === parcel.FarmID);
    if (farm) {
      const unit = this.domains.find((domain: Domain) => domain.id === farm.UnitID);
      if (unit) {
        let unitRequest = uploadRequest.Units.find((unitR) => unitR.id === unit.id);
        if (!unitRequest) {
          unitRequest = {
            id: unit.id,
            Name: unit.Name.toString(),
            Farms: []
          };
          uploadRequest.Units.push(unitRequest);
        }
        let farmRequest = unitRequest.Farms.find((farmR) => farmR.id === farm.id);
        if (!farmRequest) {
          farmRequest = {
            id: farm.id,
            Name: farm.Name.toString(),
            Code: (farm.Code || '').toString(),
            Parcels: []
          };
          unitRequest.Farms.push(farmRequest);
        }
        if (parcel.isDeleted) {
          farmRequest.Parcels.push({
            id: parcel.id,
            Deleted: parcel.Deleted
          });
        } else {
          farmRequest.Parcels.push({
            id: parcel.id,
            Name: parcel.Name.toString(),
            Created: shapeFeature.properties[this.propertiesCreationDateName]
              ? new Date(shapeFeature.properties[this.propertiesCreationDateName]).toISOString()
              : parcel.Created,
            Deleted: shapeFeature.properties[this.propertiesDeletionDateName]
              ? new Date(shapeFeature.properties[this.propertiesDeletionDateName]).toISOString()
              : parcel.Deleted,
            Planted: shapeFeature.properties[this.propertiesPlantingDateName]
              ? new Date(shapeFeature.properties[this.propertiesPlantingDateName]).toISOString()
              : parcel.Planted,
            Variety: parcel.Variety,
            Status: parcel.Status,
            Rotation: parcel.Rotation,
            FarmerName: parcel.FarmerName,
            FarmerCode: parcel.FarmerCode,
            CropSeason: parcel.CropSeason,
            FarmerPhoneNumber: parcel.FarmerPhoneNumber,
            Division: parcel.Division,
            LastHarvestDate: shapeFeature.properties[this.propertiesHarvestingDateName] || null,
            Shape: featureCollection([feature(shapeFeature.geometry)])
          });
        }
      }
    }
  }

  private extendFarmsWithShape(): Promise<void> {
    const farmsByShapeId: { [key: string]: Farm } = {};
    this.farms.forEach((farm: Farm) => {
      if (!farm.Shape && !!farm.ShapeID) {
        farmsByShapeId[farm.ShapeID] = farm;
      }
    });
    const ids = Object.keys(farmsByShapeId);
    return ids.length > 0
      ? API.getShapesByIds(ids).then((shapes: Shape[]) => {
          if (shapes) {
            shapes.forEach((shape: Shape) => {
              if (shape && farmsByShapeId[shape.id]) {
                farmsByShapeId[shape.id].Shape = shape;
              }
            });
          }
        })
      : Promise.resolve();
  }

  private checkFarmOverlapping(): Promise<boolean> {
    return new Promise((resolve) => {
      this.extendFarmsWithShape().then(() => {
        const onMessage = (result: MessageEvent) => {
          if (result.data.progress) {
            this.$store.dispatch('setGlobalLoaderMessage', result.data.progress);
            return;
          }
          this.farmOverlapMapping = result.data;
          if (this.farmOverlapMapping.length) {
            this.isManageFarmOverlappingVisible = true;
          }
          resolve(!!this.farmOverlapMapping.length);
          farmOverlapWorker.removeEventListener('message', onMessage);
        };

        farmOverlapWorker.addEventListener('message', onMessage);
        farmOverlapWorker.postMessage({
          uploadRequest: this.uploadRequest,
          farms: this.farms
        });
      });
    });
  }

  private checkSourceParcelsOverlapping(): Promise<boolean> {
    return new Promise((resolve) => {
      const onMessage = (result: MessageEvent) => {
        if (result.data.progress) {
          this.$store.dispatch('setGlobalLoaderMessage', result.data.progress);
          return;
        }
        this.sourceParcelOverlapMapping = result.data;
        if (this.sourceParcelOverlapMapping.length) {
          this.isManageSourceParcelOverlappingVisible = true;
        }
        resolve(!!this.sourceParcelOverlapMapping.length);
        sourceParcelOverlapWorker.removeEventListener('message', onMessage);
      };
      sourceParcelOverlapWorker.addEventListener('message', onMessage);
      sourceParcelOverlapWorker.postMessage({
        uploadRequest: this.uploadRequest
      });
    });
  }

  addToUploadRequestDbChanges(uploadRequest: UploadRequest): void {
    if (this.shapeEditingResult) {
      Object.keys(this.shapeEditingResult.deletedDbParcels).forEach((id) => {
        const parcel = this.shapeEditingResult.deletedDbParcels[id].parcel;
        if (parcel) {
          parcel.isDeleted = true;
          parcel.Deleted = this.shapeEditingResult.deletedDbParcels[id].deletedDate;
          this.addToUploadRequest(parcel, uploadRequest, null, parcel.Farm);
        }
      });

      Object.keys(this.shapeEditingResult.editedDbParcels).forEach((id) => {
        const parcel = this.shapeEditingResult.editedDbParcels[id].parcel;
        if (parcel) {
          this.addToUploadRequest(
            parcel,
            uploadRequest,
            this.shapeEditingResult.editedDbParcels[id].feature,
            parcel.Farm
          );
        }
      });
    }
  }

  upload(): void {
    this.updateShapeProperties();

    const uploadRequest = {
      CountryID: this.selectedCountryId,
      CropID: this.selectedCropTypeId,
      OrganizationID: this.selectedEntityId,
      Units: []
    } as UploadRequest;

    this.shape.features.forEach((shapeFeature: Feature) => {
      if (shapeFeature.properties.parcel) {
        const parcel = this.parcels.find((parcel: Parcel) => parcel.id === shapeFeature.properties.parcel);
        if (parcel) {
          this.addToUploadRequest(parcel, uploadRequest, shapeFeature);
        }
      }
    });
    this.addToUploadRequestDbChanges(uploadRequest);

    this.parcels
      .filter((parcel) => parcel.isDeleted)
      .forEach((parcel: Parcel) => {
        this.addToUploadRequest(parcel, uploadRequest, null);
      });

    this.uploadRequest = uploadRequest;

    this.$store.dispatch('showGlobalLoader', 'Checking farm overlapping...');
    this.checkFarmOverlapping().then((hasOverlaping: boolean) => {
      if (hasOverlaping) {
        this.$store.dispatch('hideGlobalLoader');
        return;
      }
      this.checkSourceOverlappingAndUpload();
    });
  }

  private doUpload(uploadRequest: UploadRequest): Promise<void> {
    uploadRequest.Units.forEach((unitR) => {
      if (isIdFake(unitR.id)) {
        unitR.id = '';
      }
      unitR.Farms.forEach((farmR) => {
        if (isIdFake(farmR.id)) {
          farmR.id = '';
        }
        farmR.Parcels.forEach((parcelR) => {
          if (isIdFake(parcelR.id)) {
            parcelR.id = '';
          }
        });
      });
    });
    return API.upload(uploadRequest);
  }

  private checkSourceOverlappingAndUpload(): void {
    this.$store.dispatch('showGlobalLoader', 'Checking source parcels overlapping...');
    this.checkSourceParcelsOverlapping().then((hasSourceParcelOverlap) => {
      if (hasSourceParcelOverlap) {
        this.$store.dispatch('hideGlobalLoader');
        return;
      }
      this.$store.dispatch('setGlobalLoaderMessage', 'Uploading...');
      this.doUpload(this.uploadRequest)
        .then(() => {
          message.success('Loading finished', 5);
          this.isSuccessfullyUploaded = true;
        })
        .catch(() => {
          message.error('Something went wrong', 5);
        })
        .finally(() => {
          this.uploadRequest = null;
          this.$store.dispatch('hideGlobalLoader');
        });
    });
  }

  private getShapeName(isRemaining = false): string {
    const nameParts = this.shapeName.split('.');
    return `${nameParts[0]}_corrected${isRemaining ? '_remaining.geojson' : '.json'}`;
  }

  private mapEntity(featureName: string, shapesNames: Mapping[], entities: Item[], dbId: string): Mapping {
    const shapeName = shapesNames.find((shapeName: Mapping) => shapeName.featureName === featureName);
    if (shapeName) {
      const entity = entities.find(({ id }) => id === dbId);
      shapeName.dbId = entity ? entity.id : null;
      shapeName.dbName = entity ? entity.Name : null;
    }
    return shapeName;
  }

  private processCreatedDomains(values: Array<[string, Domain]>): void {
    values.forEach((value: [string, Domain]) => {
      const shapeName = this.shapeDomainNames.find(({ featureName }) => value[0] === featureName);
      if (shapeName) {
        shapeName.dbId = value[1].id;
        shapeName.dbName = value[1].Name;
      }
      this.domains.push(value[1]);
    });
    this.reloadFarms();
  }

  private createNewDomain(shapeName: string, name: string): [string, Domain] {
    return [
      shapeName,
      {
        Name: name,
        id: getFakeId()
      }
    ];
  }

  private processCreatedFarms(values: Array<[string, Farm]>): void {
    values.forEach((value: [string, Farm]) => {
      const shapeName = this.shapeFarmNames.find(
        ({ featureName, featureCode }) => value[0] === featureName + '|' + featureCode
      );
      if (shapeName) {
        shapeName.dbId = value[1].id;
        shapeName.dbName = value[1].Name;
        shapeName.dbCode = value[1].Code;
      }
      this.farms.push(value[1]);
    });
    this.reloadParcels();
  }

  private createNewFarm(shapeName: string, name: string, code: string, domainId: string): [string, Farm] {
    return [
      shapeName,
      {
        Name: name,
        Code: code,
        UnitID: domainId,
        id: getFakeId()
      }
    ];
  }

  private processCreatedParcels(values: Array<[string, Parcel]>): void {
    values.forEach((value: [string, Parcel]) => {
      const shapeName = this.shapeParcelNames.find(({ featureName }) => value[0] === featureName);
      if (shapeName) {
        shapeName.dbId = value[1].id;
        shapeName.dbName = value[1].Name;
      }
      this.parcels.push(value[1]);
    });
  }

  private createNewParcel(
    shapeName: string,
    plantingDate: string,
    creationDate: string,
    harvestDates: string[],
    name: string,
    farmId: string,
    variety: string,
    status: string,
    rotation: string,
    farmerName: string,
    farmerCode: string,
    cropSeason: string,
    farmerPhoneNumber: string,
    division: string
  ): [string, Parcel] {
    return [
      shapeName,
      {
        Name: name,
        FarmID: farmId,
        Planted: new Date(plantingDate).toISOString(),
        Created: new Date(creationDate).toISOString(),
        id: getFakeId(),
        Variety: variety,
        Status: status,
        Rotation: rotation,
        ShapeID: null,
        Shape: null,
        Deleted: null,
        HarvestDates: harvestDates,
        FarmerName: farmerName,
        FarmerCode: farmerCode,
        CropSeason: cropSeason,
        FarmerPhoneNumber: farmerPhoneNumber,
        Division: division
      }
    ];
  }

  createAllDomains(): void {
    if (this.shapeDomainNames && this.shapeDomainNames.length) {
      const domains = this.shapeDomainNames
        .filter(({ dbId }) => !dbId)
        .map((shape: Mapping) => {
          return this.createNewDomain(shape.featureName, shape.featureName);
        });
      this.processCreatedDomains(domains);
    }
  }

  createDomain(name: string): void {
    this.createDomainItemName = name;
  }

  closeCreateDomain(): void {
    this.createDomainItemName = null;
  }

  onCreateDomain(name: string): void {
    if (name) {
      this.processCreatedDomains([this.createNewDomain(this.createDomainItemName, name)]);
    }
    this.closeCreateDomain();
  }

  mapDomain(name: string): void {
    this.mapDomainItemName = name;
  }

  closeMapDomain(): void {
    this.mapDomainItemName = null;
  }

  onMapDomain(id: string): void {
    this.mapEntity(this.mapDomainItemName, this.shapeDomainNames, this.domains, id);
    this.closeMapDomain();
    this.reloadFarms();
  }

  createAllFarms(): void {
    if (this.shapeFarmNames && this.shapeFarmNames.length) {
      const farms: Array<[string, Farm]> = [];
      const shapeFarmNames = this.shapeFarmNames.filter(({ dbId }) => !dbId);
      shapeFarmNames.forEach((shape: FarmMapping) => {
        const shapeDomain = this.shapeDomainNames.find(({ featureName }) => featureName === shape.featureDomainName);
        if (shapeDomain) {
          farms.push(
            this.createNewFarm(
              shape.featureName + '|' + shape.featureCode,
              shape.featureName,
              shape.featureCode,
              shapeDomain.dbId
            )
          );
        }
      });
      this.processCreatedFarms(farms);
    }
  }

  createFarm(name: string, code: string): void {
    this.createFarmItemName = name;
    this.createFarmItemCode = code;
  }

  closeCreateFarm(): void {
    this.createFarmItemName = null;
    this.createFarmItemCode = null;
  }

  onCreateFarm(name: string, code: string): void {
    if (name) {
      const shapeFarm = this.shapeFarmNames.find((farmMapping: FarmMapping) => {
        const featureName =
          farmMapping.featureName !== null && farmMapping.featureName !== undefined
            ? farmMapping.featureName.toString()
            : '';
        return (
          featureName === this.createFarmItemName.toString() &&
          (farmMapping.featureCode ? farmMapping.featureCode.toString() === this.createFarmItemCode.toString() : true)
        );
      });
      if (shapeFarm) {
        const shapeDomain = this.shapeDomainNames.find(
          ({ featureName }) => featureName === shapeFarm.featureDomainName
        );
        if (shapeDomain) {
          this.processCreatedFarms([
            this.createNewFarm(this.createFarmItemName + '|' + this.createFarmItemCode, name, code, shapeDomain.dbId)
          ]);
        }
      }
    }
    this.closeCreateFarm();
  }

  mapFarm(name: string): void {
    this.mapFarmItemName = name;
  }

  closeMapFarm(): void {
    this.mapFarmItemName = null;
  }

  onMapFarm(id: string): void {
    this.mapEntity(this.mapFarmItemName, this.shapeFarmNames, this.farms, id);
    this.closeMapFarm();
    this.reloadParcels();
  }

  mapParcel(name: string): void {
    this.mapParcelItemName = name;
  }

  closeMapParcel(): void {
    this.mapParcelItemName = null;
  }

  onMapParcel(id: string): void {
    const entity = this.mapEntity(this.mapParcelItemName, this.shapeParcelNames, this.parcels, id);
    if (entity) {
      (entity as ParcelMapping).overlapMapping = [];
    }
    this.closeMapParcel();
  }

  createAllParcels(): void {
    if (this.shapeParcelNames && this.shapeParcelNames.length) {
      const parcels: Array<[string, Parcel]> = [];
      const shapeParcelNames = this.shapeParcelNames.filter(({ dbId }) => !dbId);
      shapeParcelNames.forEach((shape: ParcelMapping) => {
        const shapeFarm = this.shapeFarmNames.find(
          ({ featureName, featureCode }) =>
            featureName === shape.featureFarmName && featureCode == shape.featureFarmCode
        );
        if (shapeFarm) {
          parcels.push(
            this.createNewParcel(
              shape.featureName,
              shape.plantingDate,
              shape.creationDate,
              shape.harvestDates,
              shape.featureName,
              shapeFarm.dbId,
              shape.variety,
              shape.status,
              shape.rotation,
              shape.farmerName,
              shape.farmerCode,
              shape.cropSeason,
              shape.farmerPhoneNumber,
              shape.division
            )
          );
        }
      });
      this.processCreatedParcels(parcels);
    }
  }

  createParcel(name: string): void {
    this.createParcelItemName = name;
  }

  closeCreateParcel(): void {
    this.createParcelItemName = null;
  }

  changeSkipUploadManually(item: ParcelMapping, isSkip: boolean): void {
    item.skipUploadManually = isSkip;
  }

  onCreateParcel(name: string): void {
    if (name) {
      const shapeParcel = this.shapeParcelNames.find(({ featureName }) => featureName === this.createParcelItemName);
      if (shapeParcel) {
        const shapeFarm = this.shapeFarmNames.find(
          ({ featureName, featureCode }) =>
            featureName === shapeParcel.featureFarmName && featureCode == shapeParcel.featureFarmCode
        );
        if (shapeFarm) {
          this.processCreatedParcels([
            this.createNewParcel(
              this.createParcelItemName,
              shapeParcel.plantingDate,
              shapeParcel.creationDate,
              shapeParcel.harvestDates,
              name,
              shapeFarm.dbId,
              shapeParcel.variety,
              shapeParcel.status,
              shapeParcel.rotation,
              shapeParcel.farmerName,
              shapeParcel.farmerCode,
              shapeParcel.cropSeason,
              shapeParcel.farmerPhoneNumber,
              shapeParcel.division
            )
          ]);
        }
      }
    }
    this.closeCreateParcel();
  }

  onAddNewParcel(shapeName: string, parcel: Parcel): void {
    this.processCreatedParcels([[shapeName, parcel]]);
  }

  manageOverlapping(): void {
    this.isManageOverlappingVisible = true;
  }

  onCloseManageOverlapping(): void {
    this.isManageOverlappingVisible = false;
  }

  onCloseFarmManageOverlappingAndProceed(): void {
    this.isManageFarmOverlappingVisible = false;
    this.checkSourceOverlappingAndUpload();
  }

  onCloseFarmManageOverlapping(): void {
    this.isManageFarmOverlappingVisible = false;
  }

  onCloseSourceParcelManageOverlapping(): void {
    this.isManageSourceParcelOverlappingVisible = false;
  }

  someFarmValidated(): boolean {
    return this.shapeFarmNames.some((farmName: FarmMapping) => farmName.validated);
  }

  onShapeEdited(result: ShapeEditingResult, shape: FeatureCollection): void {
    this.shapeEditingResult = result;
    this.shape = shape;
    this.isShapeEditingVisible = false;
    this.onSelectParcel();
  }
  onDatesModified(modifiedDates: { props: any; plantingDate: string }[]): void {
    this.modifiedDates = modifiedDates;
  }
  manageParcelsForFarms(): void {
    this.isShapeEditingVisible = true;
  }

  private clustering(input: FeatureCollection): Promise<FeatureCollection> {
    return new Promise((resolve) => {
      const onMessage = (result: MessageEvent) => {
        if (result.data.progress) {
          this.$store.dispatch('setGlobalLoaderMessage', result.data.progress);
          return;
        }
        resolve(result.data);
        clusteringWorker.removeEventListener('message', onMessage);
      };
      clusteringWorker.addEventListener('message', onMessage);
      clusteringWorker.postMessage({
        input: input
      });
    });
  }

  async mahindraValidation(event: Event): Promise<void> {
    const getOverlap = (feature: any, feature2: any) => {
      const intersection = polygonClipping.intersection(feature.geometry.coordinates, feature2.geometry.coordinates);
      let parcelIntersect = null;
      if (intersection && intersection.length) {
        parcelIntersect = intersection.length === 1 ? polygon(intersection[0]) : multiPolygon(intersection);
      }
      if (parcelIntersect) {
        const intersectArea = area(parcelIntersect);
        const featureArea = area(feature);
        const parcelArea = area(feature2);
        const intersectAreaPercentage = Math.round(((intersectArea * 100) / parcelArea) * 100) / 100;
        return {
          feature,
          feature2,
          intersectArea,
          percent: intersectAreaPercentage,
          hasEqualAreas: featureArea === parcelArea,
          intersect: parcelIntersect
        };
      }
      return null;
    };
    const cleanGeojson = (fc: any) => {
      const features = fc.features;
      let isValid = true;
      let stats: any = {
        total: 0,
        kinks: { num: 0, parcels: [] },
        overlaps: { num: 0, parcels: [] },
        triangles: { num: 0, parcels: [] },
        rejected: 0
      };
      features.forEach((f: any) => {
        stats.total++;
        if (f.properties.Rejected) {
          stats.rejected++;
          return;
        }
        // 1. kinks detection
        if (kinks(f).features.length > 0 || f.properties.HasKinks === true) {
          isValid = false;
          stats.kinks.num++;
          stats.kinks.parcels.push(f.properties.Parcel);
          f.properties.HasKinks = true;
        } else {
          f.properties.HasKinks = false;
        }
        // 2. area diff
        f.properties.areaCalculated = area(f) / 10000;
        f.properties.areaDiffPercent =
          (100 * Math.abs(f.properties.Area - f.properties.areaCalculated)) / f.properties.Area;
        // 3. overlapping (many polygons or big overlap)
        let count = 1;
        f.properties.HasOverlap = undefined;
        f.properties.MaxOverlap = undefined;
        features.forEach((f2: any) => {
          if (f === f2) return;
          if (f2.properties.Rejected) {
            return;
          }
          const overlap = getOverlap(f2, f);
          if (overlap && overlap.percent > 0) {
            f.properties.HasOverlap = true;
            if (f.properties.MaxOverlap) {
              f.properties.MaxOverlap = Math.max(f.properties.MaxOverlap, overlap.percent);
            } else {
              f.properties.MaxOverlap = overlap.percent;
            }
            f.properties['Overlap' + count] = overlap.percent;
            count++;
          }
        });
        if (f.properties.HasOverlap) {
          isValid = false;
          stats.overlaps.num++;
          stats.overlaps.parcels.push(f.properties.Parcel);
        } else {
          f.properties.HasOverlap = false;
        }
        // 4. Triangles
        if (f.geometry.coordinates[0].length === 4) {
          isValid = false;
          f.properties.Triangle = true;
          stats.triangles.num++;
          stats.triangles.parcels.push(f.properties.Parcel);
        } else {
          f.properties.Triangle = false;
        }
      });
      const REJECTION_MAX = 30;
      const rejectionRate = (100 * stats.rejected) / stats.total;
      if (rejectionRate > REJECTION_MAX) {
        isValid = false;
      }
      let out = 'Cluster is ' + (isValid ? 'VALID' : 'NOT VALID') + '\n';
      out += 'Rejection rate: ' + Math.round(rejectionRate) + `% (Max accepted ${REJECTION_MAX}%)\n`;
      out += JSON.stringify(stats, null, 4);
      return out;
    };
    this.mahindraValidationOutput = '';
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length) {
      await this.$store.dispatch('showGlobalLoader', 'Loading and validating file...');
      const jsonData = JSON.parse((await this.readFile(files[0], true)) as string) as FeatureCollection;
      this.mahindraValidationOutput = cleanGeojson(jsonData);
      await this.$store.dispatch('hideGlobalLoader');
    }
  }
}
